/* eslint-disable @typescript-eslint/no-explicit-any */
import apiClient from './apiClient';

interface getDealerPayload {
	code: string;
	gRecaptchaResponse: {
		token: string;
	};
}

interface submitContactPayload {
	contact: {
		name: string;
		email: string;
		dealer: string;
		mobile: string;
	};
	questions: {
		1: string;
		2: string;
		3: string;
		4: string;
		5: string;
		6: string;
		7: string;
	};
	answers: {
		1: string;
		2: string;
		3: string;
		4: string;
		5: string;
		6: string;
		7: string;
	};
	gRecaptchaResponse: {
		token: string;
	};
}

const onboardingAPI = {
	getDealer(payload: getDealerPayload): Promise<any> {
		return apiClient.post('/get-dealer', payload, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
	},

	submitContact(payload: submitContactPayload): Promise<any> {
		return apiClient.post('/submit-form', payload, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
	},

	serverWakeup(): Promise<any> {
		return apiClient.get('/wakeup');
	},
};

export default onboardingAPI;
