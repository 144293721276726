/* eslint-disable react/prop-types */
import React from 'react';

import {
	FormControl,
	FormLabel,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';

import '../Styles/Panels.css';

interface IProps {
	question1: string;
	question2: string;
	question3: string;
	question4: string;
	setQuestion1: (question1: string) => void;
	setQuestion2: (question2: string) => void;
	setQuestion3: (question3: string) => void;
	setQuestion4: (question4: string) => void;
}

function QuestionSet1(props: IProps): JSX.Element {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChange = (event: any, number: number) => {
		if (number === 1) {
			props.setQuestion1(event.target.value);
		} else if (number === 2) {
			props.setQuestion2(event.target.value);
		} else if (number === 3) {
			props.setQuestion3(event.target.value);
		} else if (number === 4) {
			props.setQuestion4(event.target.value);
		}
	};

	return (
		<FormControl className='question-container' component='fieldset'>
			<FormLabel
				className='question-label'
				component='legend'
				id='question-1'>
				1. Have you taken any previous training with Applied Concepts?
			</FormLabel>
			<RadioGroup
				onChange={(event) => handleChange(event, 1)}
				className='radio-group'
				aria-label='question-1'
				name='row-radio-buttons-group'
				value={props.question1}
				id='question-1-answers'>
				<FormControlLabel
					value='true'
					control={<Radio />}
					label='Yes'
				/>
				<FormControlLabel
					value='false'
					control={<Radio />}
					label='No'
				/>
			</RadioGroup>
			<FormLabel
				className='question-label'
				component='legend'
				id='question-2'>
				2. What type of phones calls do you handle?
			</FormLabel>
			<RadioGroup
				onChange={(event) => handleChange(event, 2)}
				className='radio-group'
				aria-label='question-2'
				name='row-radio-buttons-group'
				value={props.question2}
				id='question-2-answers'>
				<FormControlLabel
					value='inbound'
					control={<Radio />}
					label='Inbound'
				/>
				<FormControlLabel
					value='outbound'
					control={<Radio />}
					label='Outbound'
				/>
				<FormControlLabel
					value='inbound/outbound'
					control={<Radio />}
					label='Inbound and Outbound'
				/>
			</RadioGroup>
			<FormLabel
				className='question-label'
				component='legend'
				id='question-3'>
				3. On average, how many phones calls do you take per day?
			</FormLabel>
			<RadioGroup
				onChange={(event) => handleChange(event, 3)}
				className='radio-group'
				aria-label='question-3'
				name='row-radio-buttons-group'
				value={props.question3}
				id='question-3-answers'>
				<FormControlLabel
					value='<10'
					control={<Radio />}
					label='Less than 10'
				/>
				<FormControlLabel
					value='11-20'
					control={<Radio />}
					label='11 - 20'
				/>
				<FormControlLabel
					value='>21'
					control={<Radio />}
					label='21 or More'
				/>
			</RadioGroup>
			<FormLabel
				className='question-label'
				component='legend'
				id='question-4'>
				4. Choose one area of performace you would most like to focus on
				and improve.
			</FormLabel>
			<RadioGroup
				onChange={(event) => handleChange(event, 4)}
				className='radio-group'
				aria-label='question-4'
				name='row-radio-buttons-group'
				value={props.question4}
				id='question-4-answers'>
				<FormControlLabel
					value='setRate'
					control={<Radio />}
					label='Appointment Set Rate'
				/>
				<FormControlLabel
					value='showRate'
					control={<Radio />}
					label='Appointment Show Rate'
				/>
				<FormControlLabel
					value='closeRate'
					control={<Radio />}
					label='Appointment Close Rate'
				/>
			</RadioGroup>
		</FormControl>
	);
}

export default QuestionSet1;
