/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import '../Styles/Home.css';
import '../Styles/Wizard.css';

import {
	Button,
	Card,
	Icon,
	SelectChangeEvent,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Typography,
} from '@mui/material';
import {
	ArrowRightAlt,
	DirectionsCar,
	DoneAll,
	PersonOutline,
} from '@mui/icons-material';
import { trackPromise } from 'react-promise-tracker';

import { History } from 'history';

import WhiteLogo from '../Assets/White Logo.png';
import QuestionSet1 from '../Components/QuestionSet1';
import QuestionSet2 from '../Components/QuestionSet2';
import ContactInfo from '../Components/ContactInfo';
import onboardingAPI from '../API/onboardingAPI';

interface IProps {
	history: History;
	handleGetStep: (step: number) => void;
}

function Home(props: IProps): JSX.Element {
	const [accountCode, setAccountCode] = React.useState('');
	const [validCode, setValidCode] = React.useState(false);
	const [step, setStep] = React.useState(1);
	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [confirmationEmail, setConfirmationEmail] = React.useState('');
	const [mobile, setMobile] = React.useState('');
	const [dealer, setDealer] = React.useState('');
	const [dealerId, setDealerId] = React.useState('');
	const [title, setTitle] = React.useState('');
	const [department, setDepartment] = React.useState('');
	const [experience, setExperience] = React.useState('');
	const [question1, setQuestion1] = React.useState('');
	const [question2, setQuestion2] = React.useState('');
	const [question3, setQuestion3] = React.useState('');
	const [question4, setQuestion4] = React.useState('');
	const [question5, setQuestion5] = React.useState('');
	const [question6, setQuestion6] = React.useState('');
	const [question7, setQuestion7] = React.useState('');
	const [question8, setQuestion8] = React.useState('');
	const [question9, setQuestion9] = React.useState('');
	const [question10, setQuestion10] = React.useState('');
	const [question11, setQuestion11] = React.useState('');
	const [question12, setQuestion12] = React.useState('');
	const [question13, setQuestion13] = React.useState('');

	const history = props.history;

	const formatPhone = (phone: any) => {
		const cleaned = ('' + phone).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3];
		}
		return null;
	};

	const handleAccountCodeInput = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAccountCode(event.target.value);
	};

	const handleNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	};

	const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleConfirmEmailInput = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setConfirmationEmail(event.target.value);
	};

	const handleDealerInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDealer(event.target.value);
	};

	const handleMobileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMobile(event.target.value);
	};

	const handleTitleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(event.target.value);
	};

	const handleDepartmentInput = (event: SelectChangeEvent) => {
		setDepartment(event.target.value);
	};

	const handleExperienceInput = (event: SelectChangeEvent) => {
		setExperience(event.target.value);
	};

	const handleCodeSubmit = async (token: string) => {
		const payload = {
			code: accountCode,
			gRecaptchaResponse: {
				token: token,
			},
		};
		try {
			const response = await trackPromise(
				onboardingAPI.getDealer(payload)
			);
			setDealer(response.data.name);
			setDealerId(response.data.id);
			setValidCode(true);
			window.scrollTo(0, 0);
		} catch (err) {
			console.log(err);
		}
	};

	const checkDisabled = () => {
		if (
			confirmationEmail.length > 0 &&
			email.length > 0 &&
			confirmationEmail === email &&
			name.length > 0 &&
			mobile.length > 0 &&
			department.length > 0 &&
			experience.length > 0
		) {
			return false;
		} else {
			return true;
		}
	};

	const getContactLabelColor = () => {
		if (step === 1) {
			return '#d65200';
		} else {
			return '#000352';
		}
	};

	const getDetailsLabelColor = () => {
		if (step === 1) {
			return '#dadad9';
		} else if (step === 2 || step === 3) {
			return '#d65200';
		} else if (step > 3) {
			return '#000352';
		}
	};

	const getConfirmLabelColor = () => {
		if (step < 4) {
			return '#dadad9';
		} else {
			return '#d65200';
		}
	};

	const handleNext = () => {
		setStep(step + 1);
		props.handleGetStep(step + 1);
		const wizardCard: any = document.getElementById('wizard-card');
		wizardCard.scrollTop = 0;
		const app: any = document.getElementById('app');
		app.scrollTop = 0;
		window.scrollTo(0, 0);
	};

	const handleStep2Next = () => {
		if (
			question1.length > 0 &&
			question2.length > 0 &&
			question3.length > 0 &&
			question4.length > 0
		) {
			handleNext();
		} else if (question1.length === 0) {
			alert('Please answer question 1');
			const question1El: HTMLElement | null =
				document.getElementById('question-1');
			const question1AnswerEl: HTMLElement | null =
				document.getElementById('question-1-answers');
			if (question1El && question1AnswerEl) {
				question1El.style.border = '2px solid red';
				question1El.style.padding = '0px 5px';
				question1El.style.scrollMargin = '10px';
				question1AnswerEl.style.border = '2px solid red';
				question1AnswerEl.style.padding = '0px 5px';
				question1El.scrollIntoView(true);
			}
		} else if (question2.length === 0) {
			alert('Please answer question 2');
			const question1El: HTMLElement | null =
				document.getElementById('question-1');
			const question1AnswerEl: HTMLElement | null =
				document.getElementById('question-1-answers');
			const question2El: HTMLElement | null =
				document.getElementById('question-2');
			const question2AnswerEl: HTMLElement | null =
				document.getElementById('question-2-answers');
			if (question1El && question1AnswerEl) {
				question1El.style.border = 'none';
				question1AnswerEl.style.border = 'none';
			}
			if (question2El && question2AnswerEl) {
				question2El.style.border = '2px solid red';
				question2El.style.padding = '0px 5px';
				question2El.style.scrollMargin = '10px';
				question2AnswerEl.style.border = '2px solid red';
				question2AnswerEl.style.padding = '0px 5px';
				question2El.scrollIntoView(true);
			}
		} else if (question3.length === 0) {
			alert('Please answer question 3');
			const question1El: any = document.getElementById('question-1');
			const question1AnswerEl: any =
				document.getElementById('question-1-answers');
			const question2El: any = document.getElementById('question-2');
			const question2AnswerEl: any =
				document.getElementById('question-2-answers');
			const question3El: any = document.getElementById('question-3');
			const question3AnswerEl: any =
				document.getElementById('question-3-answers');
			question1El.style.border = 'none';
			question1AnswerEl.style.border = 'none';
			question2El.style.border = 'none';
			question2AnswerEl.style.border = 'none';
			question3El.style.border = '2px solid red';
			question3El.style.padding = '0px 5px';
			question3El.style.scrollMargin = '10px';
			question3AnswerEl.style.border = '2px solid red';
			question3AnswerEl.style.padding = '0px 5px';
			question3El.scrollIntoView(true);
		} else if (question4.length === 0) {
			alert('Please answer question 4');
			const question1El: any = document.getElementById('question-1');
			const question1AnswerEl: any =
				document.getElementById('question-1-answers');
			const question2El: any = document.getElementById('question-2');
			const question2AnswerEl: any =
				document.getElementById('question-2-answers');
			const question3El: any = document.getElementById('question-3');
			const question3AnswerEl: any =
				document.getElementById('question-3-answers');
			const question4El: any = document.getElementById('question-4');
			const question4AnswerEl: any =
				document.getElementById('question-4-answers');
			question1El.style.border = 'none';
			question1AnswerEl.style.border = 'none';
			question2El.style.border = 'none';
			question2AnswerEl.style.border = 'none';
			question3El.style.border = 'none';
			question3AnswerEl.style.border = 'none';
			question4El.style.border = '2px solid red';
			question4El.style.padding = '0px 5px';
			question4El.style.scrollMargin = '10px';
			question4AnswerEl.style.border = '2px solid red';
			question4AnswerEl.style.padding = '0px 5px';
			question4El.scrollIntoView(true);
		}
	};

	const handleBack = () => {
		setStep(step - 1);
		props.handleGetStep(step - 1);
		const wizardCard: any = document.getElementById('wizard-card');
		wizardCard.scrollTop = 0;
		const app: any = document.getElementById('app');
		app.scrollTop = 0;
		window.scrollTo(0, 0);
	};

	const resetState = () => {
		setName('');
		setEmail('');
		setConfirmationEmail('');
		setDealer('');
		setDealerId('');
		setQuestion1('');
		setQuestion2('');
		setQuestion3('');
		setQuestion4('');
		setQuestion5('');
		setQuestion6('');
		setQuestion7('');
		setQuestion8('');
		setQuestion9('');
		setQuestion10('');
		setQuestion11('');
		setQuestion12('');
		setQuestion13('');
	};

	const handleOnClick = (e: any) => {
		e.preventDefault();
		window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
					action: 'submit',
				})
				.then((token: string) => {
					if (name === '') {
						handleCodeSubmit(token);
					} else {
						handleSubmit(token);
					}
				});
		});
	};

	const handleSubmit = async (token: string) => {
		const payload = {
			contact: {
				name: name,
				title: title,
				department: department,
				experience: experience,
				email: email,
				mobile: formatPhone(mobile),
				dealer: dealer,
				dealerid: dealerId,
			},
			questions: {
				1: 'Have you taken any previous training with Applied Concepts?',
				2: 'What type of phones calls do you handle?',
				3: 'On average, how many phones calls do you take per day?',
				4: 'Choose one area of performace you would most like to focus on and improve',
				5: 'Multi-Channel Communication with Customer',
				6: 'Developing Lifelong Relationships with Customers',
				7: 'Cross-Selling Sales and Service',
				8: 'Finance and Insurance',
				9: 'Maximizing Trade-In Profit',
				10: 'Lease Retention',
				11: 'Orphan Owner',
				12: 'Unsold Follow-Up',
				13: 'Equity Mining',
			},
			answers: {
				1: question1,
				2: question2,
				3: question3,
				4: question4,
				5: question5,
				6: question6,
				7: question7,
				8: question8,
				9: question9,
				10: question10,
				11: question11,
				12: question12,
				13: question13,
			},
			gRecaptchaResponse: {
				token: token,
			},
		};
		try {
			const response = await trackPromise(
				onboardingAPI.submitContact(payload)
			);
			console.log(response);
			setStep(step + 1);
			props.handleGetStep(step + 1);
			const wizardCard: any = document.getElementById('wizard-card');
			wizardCard.scrollTop = 0;
			const app: any = document.getElementById('app');
			app.scrollTop = 0;
			window.scrollTo(0, 0);
			resetState();
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		const loadScriptByURL = (
			id: string,
			url: string,
			callback: () => void
		) => {
			const isScriptExist = document.getElementById(id);

			if (!isScriptExist) {
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = url;
				script.id = id;
				script.onload = function () {
					if (callback) callback();
				};
				document.body.appendChild(script);
			}

			if (isScriptExist && callback) callback();
		};

		const wakeup = async () => {
			try {
				const response = await trackPromise(
					onboardingAPI.serverWakeup()
				);
				console.log(response);
			} catch (err) {
				console.log(err);
			}
		};

		// load the script by passing the URL
		// eslint-disable-next-line no-undef
		wakeup();
		loadScriptByURL(
			'recaptcha-key',
			`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`,
			function () {
				console.log('Script loaded!');
			}
		);
		if (history.location.search !== '') {
			const accountCode = history.location.search.substring(1);
			setAccountCode(accountCode);
		}
	}, []);

	return (
		<div className='root'>
			<div id='cta-container' className='cta-container'>
				<div className='cta-inner-container'>
					<img
						className='logo'
						src={WhiteLogo}
						alt='Applied concepts logo'
					/>
					<div className='title'>
						<Typography
							//eslint-disable-next-line
							sx={{ fontFamily: "'League Spartan', sans-serif" }}
							variant='h5'>
							Onboarding
						</Typography>
					</div>
					<div className='cta'>
						<Typography
							variant='h3'
							sx={{
								fontWeight: 'bold',
								fontFamily: 'Open Sans',
							}}>
							Welcome! Let's get you enrolled in your training.
						</Typography>
					</div>
					{!validCode && (
						<React.Fragment>
							<div className='mobile-title'>
								<Typography
									sx={{
										fontFamily:
											//eslint-disable-next-line
											"'League Spartan', sans-serif",
									}}
									variant='h5'>
									Onboarding
								</Typography>
							</div>
							<div className='mobile-cta'>
								<Typography
									variant='h3'
									sx={{
										fontWeight: 'bold',
										fontFamily: 'Open Sans',
									}}>
									Welcome! Let's get you enrolled in your
									training.
								</Typography>
							</div>
						</React.Fragment>
					)}
					{validCode && step === 1 && (
						<div className='mobile-step1-cta'>
							<Typography
								variant='h5'
								sx={{
									fontWeight: 'bold',
									fontFamily: 'Open Sans',
								}}>
								Contact Information
							</Typography>
						</div>
					)}
					{validCode && (step === 2 || step === 3) && (
						<div className='mobile-step1-cta'>
							<Typography
								variant='h5'
								sx={{
									fontWeight: 'bold',
									fontFamily: 'Open Sans',
								}}>
								Survey
							</Typography>
						</div>
					)}
					{validCode && (step === 4 || step === 5) && (
						<div className='mobile-step1-cta'>
							<Typography
								variant='h5'
								sx={{
									fontWeight: 'bold',
									fontFamily: 'Open Sans',
								}}>
								Confirmation
							</Typography>
						</div>
					)}
				</div>
				{!validCode && (
					<React.Fragment>
						<Card className='dealerCode-card'>
							<form
								className='dealerCode-container'
								onSubmit={handleOnClick}>
								<div className='dealerCode-container'>
									<span className='dealerCode-label'>
										Enter Your Enrollment Code
									</span>
									<div className='dealerCode-input-container'>
										<TextField
											className='dealerCode-input'
											variant='outlined'
											value={accountCode}
											onChange={handleAccountCodeInput}
											sx={{ textAlign: 'center' }}
										/>
									</div>
									<Button
										className='dealerCode-submit'
										disabled={accountCode.length !== 4}
										type='submit'>
										Submit
									</Button>
								</div>
							</form>
							<div className='dealerCode-orange'></div>
						</Card>
					</React.Fragment>
				)}
				{validCode && (
					<Card id='wizard-card' className='wizard-card'>
						<Stepper
							className='wizard-stepper'
							activeStep={step}
							alternativeLabel>
							<Step>
								<StepLabel
									icon={
										<PersonOutline
											className='step-icon-white'
											style={{
												backgroundColor:
													getContactLabelColor(),
											}}
										/>
									}>
									Contact Info
								</StepLabel>
							</Step>
							<Step>
								<StepLabel
									icon={
										<DirectionsCar
											className={
												step >= 2
													? 'step-icon-white'
													: 'step-icon-grey'
											}
											style={{
												backgroundColor:
													getDetailsLabelColor(),
											}}
										/>
									}>
									Survey
								</StepLabel>
							</Step>
							<Step>
								<StepLabel
									icon={
										<DoneAll
											className={
												step >= 4
													? 'step-icon-white'
													: 'step-icon-grey'
											}
											style={{
												backgroundColor:
													getConfirmLabelColor(),
											}}
										/>
									}>
									Confirmation
								</StepLabel>
							</Step>
						</Stepper>
						{step === 1 && (
							<div className='step-container'>
								<form onSubmit={handleNext}>
									<ContactInfo
										{...props}
										name={name}
										email={email}
										confirmationEmail={confirmationEmail}
										mobile={mobile}
										dealer={dealer}
										title={title}
										department={department}
										experience={experience}
										handleNameInput={handleNameInput}
										handleEmailInput={handleEmailInput}
										handleConfirmEmailInput={
											handleConfirmEmailInput
										}
										handleMobileInput={handleMobileInput}
										handleDealerInput={handleDealerInput}
										handleTitleInput={handleTitleInput}
										handleDepartmentInput={
											handleDepartmentInput
										}
										handleExperienceInput={
											handleExperienceInput
										}
									/>
									<div className='step1-next'>
										<Button
											disabled={checkDisabled()}
											type='submit'>
											Next
											<Icon className='step-next-button-icon'>
												<ArrowRightAlt
													className={
														checkDisabled()
															? 'grey'
															: 'white'
													}
												/>
											</Icon>
										</Button>
									</div>
								</form>
							</div>
						)}
						{step === 2 && (
							<div className='step-container'>
								<QuestionSet1
									{...props}
									question1={question1}
									question2={question2}
									question3={question3}
									question4={question4}
									setQuestion1={setQuestion1}
									setQuestion2={setQuestion2}
									setQuestion3={setQuestion3}
									setQuestion4={setQuestion4}
								/>
								<div className='step-button-container'>
									<div>
										<Button onClick={handleBack}>
											<Icon className='step-back-button-icon'>
												<ArrowRightAlt className='white' />
											</Icon>
											Contact Info
										</Button>
									</div>
									<div>
										<Button
											disabled={
												question1 === '' ||
												question2 === '' ||
												question3 === '' ||
												question4 === ''
											}
											onClick={handleStep2Next}>
											Next
											<Icon className='step-next-button-icon'>
												<ArrowRightAlt className='white' />
											</Icon>
										</Button>
									</div>
								</div>
							</div>
						)}
						{step === 3 && (
							<div className='step-container'>
								<QuestionSet2
									{...props}
									question5={question5}
									question6={question6}
									question7={question7}
									question8={question8}
									question9={question9}
									question10={question10}
									question11={question11}
									question12={question12}
									question13={question13}
									setQuestion5={setQuestion5}
									setQuestion6={setQuestion6}
									setQuestion7={setQuestion7}
									setQuestion8={setQuestion8}
									setQuestion9={setQuestion9}
									setQuestion10={setQuestion10}
									setQuestion11={setQuestion11}
									setQuestion12={setQuestion12}
									setQuestion13={setQuestion13}
								/>
								<div className='step-button-container'>
									<div>
										<Button onClick={handleBack}>
											<Icon className='step-back-button-icon'>
												<ArrowRightAlt className='white' />
											</Icon>
											Back
										</Button>
									</div>
									<div>
										<Button
											disabled={
												question5 === '' ||
												question6 === '' ||
												question7 === '' ||
												question8 === '' ||
												question9 === '' ||
												question10 === '' ||
												question11 === '' ||
												question12 === '' ||
												question13 === ''
											}
											onClick={handleNext}>
											Next
											<Icon className='step-next-button-icon'>
												<ArrowRightAlt className='white' />
											</Icon>
										</Button>
									</div>
								</div>
							</div>
						)}
						{step === 4 && (
							<div className='confirmation'>
								<div className='confirmation-text-container'>
									<div
										style={{
											display: 'flex',
											marginBottom: 25,
											alignItems: 'center',
										}}>
										<span className='confirmation-label'>
											Name:{' '}
										</span>
										<Typography className='confirmation-value'>
											{name}
										</Typography>
									</div>
									<div
										style={{
											display: 'flex',
											margin: '25px 0px',
										}}>
										<span className='confirmation-label'>
											Email:{' '}
										</span>
										<Typography className='confirmation-value'>
											{email}
										</Typography>
									</div>
									<div style={{ display: 'flex' }}>
										<span className='confirmation-label'>
											Dealer:{' '}
										</span>
										<Typography className='confirmation-value'>
											{dealer}
										</Typography>
									</div>
								</div>
								<div className='confirmation-button-container'>
									<div>
										<Button onClick={handleBack}>
											<Icon className='step-back-button-icon'>
												<ArrowRightAlt className='white' />
											</Icon>
											Back
										</Button>
									</div>
									<div>
										<Button onClick={handleOnClick}>
											Submit
										</Button>
									</div>
								</div>
							</div>
						)}
						{step === 5 && (
							<div className='complete-container'>
								<div className='complete-congrats'>
									Congratulations, you're almost done!
								</div>
								<div className='complete-cta'>
									Please check your email. Click the link to
									verify your email and complete the
									onboarding process.
								</div>
								{/* <Button
									onClick={() =>
										history.push('/confirmation')
									}>
									Finish
								</Button> */}
							</div>
						)}
					</Card>
				)}
			</div>
		</div>
	);
}

export default Home;
