/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import '../Styles/Panels.css';

interface IContactInfoProps {
	name: string;
	email: string;
	confirmationEmail: string;
	mobile: string;
	dealer: string;
	title: string;
	department: string;
	experience: string;
	handleTitleInput: (event: SelectChangeEvent) => void;
	handleExperienceInput: (event: SelectChangeEvent) => void;
	handleDepartmentInput: (event: SelectChangeEvent) => void;
	handleNameInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleEmailInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleConfirmEmailInput: (
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
	handleMobileInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleDealerInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function ContactInfo(props: IContactInfoProps): JSX.Element {
	const emailRegex =
		/^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/;

	const handlePreventDefault = (event: any) => {
		event.preventDefault();
	};

	const formatPhone = (phone: any) => {
		if (phone.length === 10) {
			const cleaned = ('' + phone).replace(/\D/g, '');
			const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
			if (match) {
				return '(' + match[1] + ') ' + match[2] + '-' + match[3];
			}
			return null;
		} else {
			return phone.replace(/[^\d]/g, '');
		}
	};

	return (
		<div className='contactInfo-container'>
			<div className='contactInfo-row'>
				<div className='contactInfo-label'>Name:</div>
				<div>
					<div className='contactInfo-mobile-label'>Name:</div>
					<TextField
						value={props.name}
						required
						variant='outlined'
						className='contactInfo-input'
						onChange={props.handleNameInput}></TextField>
				</div>
			</div>
			<div className='contactInfo-row'>
				<div className='contactInfo-label'>Title:</div>
				<div>
					<div className='contactInfo-mobile-label'>Title:</div>
					<Select
						className='contactInfo-input'
						value={props.title}
						onChange={props.handleTitleInput}>
						<MenuItem value='Sales'>Sales</MenuItem>
						<MenuItem value='Sales Manager'>Sales Manager</MenuItem>
						<MenuItem value='Service'>Service</MenuItem>
						<MenuItem value='Parts'>Parts</MenuItem>
						<MenuItem value='BDC Sales'>BDC Sales</MenuItem>
						<MenuItem value='BDC Sales Manager'>BDC Sales Manager</MenuItem>
						<MenuItem value='BDC Service'>BDC Service</MenuItem>
						<MenuItem value='BDC Service Manager'>BDC Service Manager</MenuItem>
						<MenuItem value='Finance'>Finance</MenuItem>
						<MenuItem value='GM'>GM</MenuItem>
						<MenuItem value='GSM'>GSM</MenuItem>
						<MenuItem value='Receptionist'>Receptionist</MenuItem>
					</Select>
				</div>
			</div>
			<div className='contactInfo-row'>
				<div className='contactInfo-label'>Department:</div>
				<div>
					<div className='contactInfo-mobile-label'>Department:</div>
					<Select
						className='contactInfo-input'
						value={props.department}
						onChange={props.handleDepartmentInput}>
						<MenuItem value='Sales'>Sales</MenuItem>
						<MenuItem value='BDC'>BDC</MenuItem>
						<MenuItem value='Service'>Service</MenuItem>
						<MenuItem value='Parts'>Parts</MenuItem>
						<MenuItem value='Other'>Other</MenuItem>
					</Select>
				</div>
			</div>
			<div className='contactInfo-row'>
				<div className='contactInfo-label'>
					Years in the Auto Industry:
				</div>
				<div>
					<div className='contactInfo-mobile-label'>
						Years in the Auto Industry:
					</div>
					<Select
						className='contactInfo-input'
						value={props.experience}
						onChange={props.handleExperienceInput}>
						<MenuItem value='<1'>Less than 1 year</MenuItem>
						<MenuItem value='1-5'>1 - 5 years</MenuItem>
						<MenuItem value='6-10'>6 - 10 years</MenuItem>
						<MenuItem value='11-15'>11 - 15 years</MenuItem>
						<MenuItem value='>15'>More than 15 years</MenuItem>
					</Select>
				</div>
			</div>
			<div className='contactInfo-row'>
				<div className='contactInfo-label'>Email:</div>
				<div>
					<div className='contactInfo-mobile-label'>Email:</div>
					<TextField
						value={props.email}
						onCopy={handlePreventDefault}
						onCopyCapture={handlePreventDefault}
						required
						variant='outlined'
						className='contactInfo-input'
						onChange={props.handleEmailInput}></TextField>
					{props.email.length > 0 &&
						!emailRegex.test(props.email) && (
							<div className='red'>*Must be a valid email</div>
						)}
				</div>
			</div>
			<div className='contactInfo-row'>
				<div className='contactInfo-label'>Confirm Email:</div>
				<div>
					<div className='contactInfo-mobile-label'>
						Confirm Email:
					</div>
					<TextField
						value={props.confirmationEmail}
						required
						onPaste={handlePreventDefault}
						variant='outlined'
						className='contactInfo-input'
						onChange={props.handleConfirmEmailInput}></TextField>
					<div className='flex'>
						{props.confirmationEmail.length > 0 &&
							!emailRegex.test(props.confirmationEmail) && (
								<div className='red'>
									*Must be a valid email
								</div>
							)}
						{props.confirmationEmail.length > 0 &&
							props.confirmationEmail !== props.email && (
								<div
									className='red'
									style={{
										marginLeft:
											props.confirmationEmail.length >
												0 &&
											props.confirmationEmail !==
												props.email
												? 10
												: 0,
									}}>
									*Emails must match
								</div>
							)}
					</div>
				</div>
			</div>
			<div className='contactInfo-row'>
				<div className='contactInfo-label'>Cell Phone:</div>
				<div>
					<div className='contactInfo-mobile-label'>Cell Phone:</div>
					<TextField
						value={formatPhone(props.mobile)}
						required
						variant='outlined'
						className='contactInfo-input'
						inputProps={{ maxLength: 10 }}
						onChange={props.handleMobileInput}></TextField>
				</div>
			</div>
			<div className='contactInfo-row'>
				<div className='contactInfo-label'>Dealer:</div>
				<div>
					<div className='contactInfo-mobile-label'>Dealer:</div>
					<TextField
						value={props.dealer}
						required
						variant='outlined'
						className='contactInfo-input'
						onChange={props.handleDealerInput}
						disabled></TextField>
				</div>
			</div>
		</div>
	);
}

ContactInfo.propTypes = {
	name: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	confirmationEmail: PropTypes.string.isRequired,
	dealer: PropTypes.string.isRequired,
	handleNameInput: PropTypes.func.isRequired,
	handleEmailInput: PropTypes.func.isRequired,
	handleConfirmEmailInput: PropTypes.func.isRequired,
	handleDealerInput: PropTypes.func.isRequired,
};

export default ContactInfo;
