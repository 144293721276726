/* eslint-disable quotes */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import WhiteLogo from '../Assets/White Logo.png';
import RoadImg from '../Assets/Road Image - greyscale.png';

import '../Styles/Confirmation.css';

import { Typography } from '@mui/material';

function Confirmation(): JSX.Element {
	return (
		<div className='confirmation-root'>
			<div className='confirmation-container'>
				<div className='confirmation-inner-container'>
					<div>
						<img
							className='confirmation-logo'
							src={WhiteLogo}
							alt='Applied concepts logo'
						/>
					</div>
					<div className='confirmation-title'>
						<Typography
							sx={{ fontFamily: "'League Spartan', sans-serif" }}
							variant='h5'>
							Email Confirmed
						</Typography>
					</div>
					<div className='confirmation-cta'>
						<Typography
							variant='h3'
							sx={{
								fontWeight: 'bold',
								fontFamily: 'Open Sans',
							}}>
							That's it! We have all we need to get you started
						</Typography>
					</div>
				</div>
				<div className='confirmation-content'>
					<div className='instructions-container'>
						<div className='orange'></div>
						<Typography className='instructions'>
							Each weekend, you will receive an email with
							instructions on how to complete your training for
							the upcoming week. We look forward to helping you
							grow in your career.
						</Typography>
					</div>
					<div className='img-container'>
						<img className='road-img' src={RoadImg} alt='Road' />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Confirmation;
