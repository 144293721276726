/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createRef } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import FadeLoader from 'react-spinners/FadeLoader';

import '../App.css';

import { Dialog } from '@mui/material';

function Spinner(): JSX.Element {
	const { promiseInProgress } = usePromiseTracker();

	const spinnerRef: any = createRef();

	return (
		<div
			style={{
				display: 'flex',
				margin: '0 auto',
				justifyContent: 'center',
			}}>
			{promiseInProgress === true ? (
				<Dialog ref={spinnerRef} open={promiseInProgress}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							margin: '0 auto',
							height: 100,
							width: 150,
						}}>
						<FadeLoader ref={spinnerRef} color={'#000352'} />
					</div>
				</Dialog>
			) : null}
		</div>
	);
}

export default Spinner;
