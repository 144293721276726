import React from 'react';

import {
	FormControl,
	FormLabel,
	Icon,
	Rating,
	Tooltip,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { Help } from '@mui/icons-material';

import '../Styles/Panels.css';
interface IProps {
	question5: string;
	setQuestion5: (question5: string) => void;
	question6: string;
	setQuestion6: (question6: string) => void;
	question7: string;
	setQuestion7: (question7: string) => void;
	question8: string;
	question9: string;
	question10: string;
	question11: string;
	question12: string;
	question13: string;
	setQuestion8: (question8: string) => void;
	setQuestion9: (question9: string) => void;
	setQuestion10: (question10: string) => void;
	setQuestion11: (question11: string) => void;
	setQuestion12: (question12: string) => void;
	setQuestion13: (question13: string) => void;
}

function QuestionSet2(props: IProps): JSX.Element {
	const handleChange = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		event: any,
		newValue: number | null,
		number: number
	) => {
		if (number === 5) {
			props.setQuestion5(newValue.toString());
		} else if (number === 6) {
			props.setQuestion6(newValue.toString());
		} else if (number === 7) {
			props.setQuestion7(newValue.toString());
		} else if (number === 8) {
			props.setQuestion8(newValue.toString());
		} else if (number === 9) {
			props.setQuestion9(newValue.toString());
		} else if (number === 10) {
			props.setQuestion10(newValue.toString());
		} else if (number === 11) {
			props.setQuestion11(newValue.toString());
		} else if (number === 12) {
			props.setQuestion12(newValue.toString());
		} else if (number === 13) {
			props.setQuestion13(newValue.toString());
		}
	};

	return (
		<FormControl className='question-container'>
			<FormLabel
				className='question-label'
				component='legend'
				id='question-1'>
				5. How would you rate your performance in the following areas?
			</FormLabel>
			<div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
				<Typography component='legend'>
					- Multi-Channel Communication with Customer
				</Typography>
				<Tooltip
					placement='right'
					title='Applying modern retailing skills to text and email communication'
					arrow>
					<Icon style={{ paddingBottom: 5 }}>
						<Help />
					</Icon>
				</Tooltip>
			</div>
			<Rating
				value={parseInt(props.question5)}
				onChange={(event, newValue) => handleChange(event, newValue, 5)}
				style={{ marginBottom: 10, marginLeft: 10 }}
			/>
			<div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
				<Typography component='legend'>
					- Developing Lifelong Relationships with Customers
				</Typography>
				<Tooltip
					placement='right'
					title='Reflecting on continuous improvement, resulting in referrals and repeat business'
					arrow>
					<Icon style={{ paddingBottom: 5 }}>
						<Help />
					</Icon>
				</Tooltip>
			</div>
			<Rating
				value={parseInt(props.question6)}
				onChange={(event, newValue) => handleChange(event, newValue, 6)}
				style={{ marginBottom: 10, marginLeft: 10 }}
			/>
			<div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
				<Typography component='legend'>
					- Cross-Selling Sales and Service
				</Typography>
				<Tooltip
					placement='right'
					title='Cross-selling as a team activity, requiring collaboration and coordination to generate multi-department sales'
					arrow>
					<Icon style={{ paddingBottom: 5 }}>
						<Help />
					</Icon>
				</Tooltip>
			</div>
			<Rating
				value={parseInt(props.question7)}
				onChange={(event, newValue) => handleChange(event, newValue, 7)}
				style={{ marginBottom: 10, marginLeft: 10 }}
			/>
			<div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
				<Typography component='legend'>
					- Finance and Insurance
				</Typography>
				<Tooltip
					placement='right'
					title='End of Warranty / Extended Warranty Contracting'
					arrow>
					<Icon style={{ paddingBottom: 5 }}>
						<Help />
					</Icon>
				</Tooltip>
			</div>
			<Rating
				value={parseInt(props.question8)}
				onChange={(event, newValue) => handleChange(event, newValue, 8)}
				style={{ marginBottom: 10, marginLeft: 10 }}
			/>
			<div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
				<Typography component='legend'>
					- Maximizing Trade-In Profit
				</Typography>
				<Tooltip
					placement='right'
					title='Positioning yourself in a way that supports the dual role (buyer and seller) during the trade negotiation to maximize profitability'
					arrow>
					<Icon style={{ paddingBottom: 5 }}>
						<Help />
					</Icon>
				</Tooltip>
			</div>
			<Rating
				value={parseInt(props.question9)}
				onChange={(event, newValue) => handleChange(event, newValue, 9)}
				style={{ marginBottom: 10, marginLeft: 10 }}
			/>
			<div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
				<Typography component='legend'>- Lease Retention</Typography>
				<Tooltip
					placement='right'
					title='Leveraging manufacturer lease incentives to gain market share'
					arrow>
					<Icon style={{ paddingBottom: 5 }}>
						<Help />
					</Icon>
				</Tooltip>
			</div>
			<Rating
				value={parseInt(props.question10)}
				onChange={(event, newValue) =>
					handleChange(event, newValue, 10)
				}
				style={{ marginBottom: 10, marginLeft: 10 }}
			/>
			<div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
				<Typography component='legend'>- Orphan Owner</Typography>
				<Tooltip
					placement='right'
					title='Identifying and migrating orphan customers to lifelong customers'
					arrow>
					<Icon style={{ paddingBottom: 5 }}>
						<Help />
					</Icon>
				</Tooltip>
			</div>
			<Rating
				value={parseInt(props.question11)}
				onChange={(event, newValue) =>
					handleChange(event, newValue, 11)
				}
				style={{ marginBottom: 10, marginLeft: 10 }}
			/>
			<div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
				<Typography component='legend'>- Unsold Follow-Up</Typography>
				<Tooltip
					placement='right'
					title='Strategic follow-up conversations to gain new customer business'
					arrow>
					<Icon style={{ paddingBottom: 5 }}>
						<Help />
					</Icon>
				</Tooltip>
			</div>
			<Rating
				value={parseInt(props.question12)}
				onChange={(event, newValue) =>
					handleChange(event, newValue, 12)
				}
				style={{ marginBottom: 10, marginLeft: 10 }}
			/>
			<div style={{ display: 'flex', height: 40, alignItems: 'center' }}>
				<Typography component='legend'>- Equity Mining</Typography>
				<Tooltip
					placement='right'
					title='Targeting existing customers to capitalize on potential equity opportunities'
					arrow>
					<Icon style={{ paddingBottom: 5 }}>
						<Help />
					</Icon>
				</Tooltip>
			</div>
			<Rating
				value={parseInt(props.question13)}
				onChange={(event, newValue) =>
					handleChange(event, newValue, 13)
				}
				style={{ marginBottom: 10, marginLeft: 10 }}
			/>
		</FormControl>
	);
}

QuestionSet2.propTypes = {
	setQuestion5: PropTypes.func.isRequired,
	setQuestion6: PropTypes.func.isRequired,
	setQuestion7: PropTypes.func.isRequired,
};

export default QuestionSet2;
