import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Spinner from './Components/Spinner';

import '@fontsource/league-spartan/700.css';
import '@fontsource/open-sans/700.css';
import Home from './Pages/Home';
import Confirmation from './Pages/Confirmation';

function App(): JSX.Element {
	const [step, setStep] = React.useState(0);

	const handleGetStep = (step: number) => {
		setStep(step);
	};

	return (
		<Router>
			<div
				id='app'
				className={step !== 4 && step !== 5 ? 'app' : 'app-setHeight'}>
				<Spinner />
				<Switch>
					<Route
						exact
						path='/'
						render={(props) => (
							<Home {...props} handleGetStep={handleGetStep} />
						)}
					/>
					<Route
						exact
						path='/confirmation'
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						render={(props: any) => <Confirmation {...props} />}
					/>
				</Switch>
			</div>
		</Router>
	);
}

export default App;
