import axios from 'axios';

const apiClient = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	}
});

export default apiClient;